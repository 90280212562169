import React from 'react';
import imgTitle from '../assets/images/we-are-sorry.png';

const Mobile = () => {
  return (
    <div className="container-fluid mobile-detection">
      <div className="row justify-content-center align-items-center align-content-center">
        <div className="col-11 content d-flex flex-column text-center justify-content-center align-items-center align-content-center">
          <img className="title" src={imgTitle} alt="" />

          <p>
            <strong>But your device is not supported at the moment.</strong>
          </p>

          <p className="small">
            For the best experience, please take this assessment on your desktop computer and not a
            mobile device.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
