import React from 'react';
import { Link } from 'react-router-dom';

import srLogo from '../assets/images/sr-hearth.png';
import imgTitle from '../assets/images/hello.svg';
import iconAlert from '../assets/images/icon_alert.svg';

const Intro = () => {
  return (
    <div className="container-fluid intro-bg">
      <div className="row h-100 justify-content-center align-items-center align-content-center">
        <div className="col-12 content d-flex flex-column text-center justify-content-center align-items-center align-content-center">
          <a href="https://www.sweetrush.com" target="_blank" rel="noreferrer">
            <img className="logo" src={srLogo} alt="" />
          </a>
          <img className="title" src={imgTitle} alt="" />
          <p className="main-text">
            <span>We have something very special to share… </span>
            <span>something to help you relax and unwind after a busy year!</span>
          </p>

          <p>But first, we need to know a little bit more about you.</p>

          <p>
            <span>
              Click Start to take a quick quiz to help us understand how you like to unwind.
            </span>
            <span>
              <strong>When you’ve finished, you’ll find a customized gift waiting for you!</strong>
            </span>
          </p>

          <p>
            While you’re doing that, we’re going to find our biggest, shiniest bow to put on top…
            that is, right after we’ve found the end of the sticky tape.
          </p>
          <p>Are you ready?</p>

          <Link className="link" to="/assessment">
            Start
          </Link>

          <p className="disclaimer-mini">
            NOTE: Although this experience was made with lots of love, we’ll admit it’s not based on
            actual science. We used knowledge and common sense—along with many hours of Google
            search—to build it. Please don’t use the results as a behavioral report for your next
            interview. What about your data? We are collecting none, nothing, not a trace!
          </p>
        </div>
      </div>

      <div className="disclaimer-devices">
        <img src={iconAlert} alt="icon alert" />
        For the best experience, please take this assessment on a larger device, such as a tablet or
        desktop computer.
      </div>
    </div>
  );
};

export default Intro;
