import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const TypeSelector = () => {
  const [inHover, setHover] = useState(false);
  const [currentHover, setCurrentHover] = useState('');

  return (
    <div className="container-fluid type-selector-container">
      <div
        className={`row categories-content ${inHover ? 'hover' : ''}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className={`col content d-flex flex-column g-0 ${
            currentHover === 'chillaxer' ? 'current-hover' : ''
          }`}
          onMouseEnter={() => setCurrentHover('chillaxer')}
          onMouseLeave={() => setCurrentHover('')}
        >
          <Link
            className="link-container"
            to={{ pathname: 'results', state: { data: { class: 'chillaxer', selector: true } } }}
          >
            <div className="overlay"></div>
            <div className="chillaxer category"></div>
          </Link>
        </div>
        <div
          className={`col content d-flex flex-column g-0 ${
            currentHover === 'wanderer' ? 'current-hover' : ''
          }`}
          onMouseEnter={() => setCurrentHover('wanderer')}
          onMouseLeave={() => setCurrentHover('')}
        >
          <Link
            className="link-container"
            to={{ pathname: 'results', state: { data: { class: 'wanderer', selector: true } } }}
          >
            <div className="overlay"></div>
            <div className="wanderer category"></div>
          </Link>
        </div>
        <div
          className={`col content d-flex flex-column g-0 ${
            currentHover === 'entertainer' ? 'current-hover' : ''
          }`}
          onMouseEnter={() => setCurrentHover('entertainer')}
          onMouseLeave={() => setCurrentHover('')}
        >
          <Link
            className="link-container"
            to={{ pathname: 'results', state: { data: { class: 'entertainer', selector: true } } }}
          >
            <div className="overlay"></div>
            <div className="entertainer category"></div>
          </Link>
        </div>
        <div
          className={`col content d-flex flex-column g-0 ${
            currentHover === 'adventurer' ? 'current-hover' : ''
          }`}
          onMouseEnter={() => setCurrentHover('adventurer')}
          onMouseLeave={() => setCurrentHover('')}
        >
          <Link
            className="link-container"
            to={{ pathname: 'results', state: { data: { class: 'adventurer', selector: true } } }}
          >
            <div className="overlay"></div>
            <div className="adventurer category"></div>
          </Link>
        </div>
      </div>
      <div className="row bottom-content align-items-center">
        <div className="col content d-flex flex-column">
          <div className="retake-container">
            <Link className="retake-test" to="/assessment">
              Take the test again
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeSelector;
