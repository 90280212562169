import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import useFetch from '../../hooks/useFetch';

import ClipLoader from 'react-spinners/ClipLoader';

import openBoxImg from '../../assets/images/boxes/chillaxer/open-box.png';

import logo from '../../assets/images/sr-love-white.svg';

import background from '../../assets/images/boxes/chillaxer/chillaxer-bg.jpg';
import backgroundOpen from '../../assets/images/boxes/chillaxer/background-open-box.jpg';
import palmShadow from '../../assets/images/boxes/chillaxer/palm-shadow.png';
import box from '../../assets/images/boxes/chillaxer/box.png';
import boxShadow from '../../assets/images/boxes/chillaxer/box-shadow.png';
import leaves1 from '../../assets/images/boxes/chillaxer/leaves-01.png';
import leaves2 from '../../assets/images/boxes/chillaxer/leaves-02.png';
import leaves3 from '../../assets/images/boxes/chillaxer/leaves-03.png';
import thing1 from '../../assets/images/boxes/chillaxer/thing-01.png';
import thing2 from '../../assets/images/boxes/chillaxer/thing-02.png';
import thing3 from '../../assets/images/boxes/chillaxer/thing-03.png';

import hover1 from '../../assets/images/boxes/chillaxer/hover-01.png';
import hover2 from '../../assets/images/boxes/chillaxer/hover-02.png';
import hover3 from '../../assets/images/boxes/chillaxer/hover-03.png';
import hover4 from '../../assets/images/boxes/chillaxer/hover-04.png';
import hover5 from '../../assets/images/boxes/chillaxer/hover-05.png';
import hover6 from '../../assets/images/boxes/chillaxer/hover-06.png';

import popupClose from '../../assets/images/boxes/chillaxer/btn-close-default.png';

import popupBg1 from '../../assets/images/boxes/chillaxer/recipes-popup-bg.png';
import popupBg2 from '../../assets/images/boxes/chillaxer/crafts-popup-bg.png';
import popupBg3 from '../../assets/images/boxes/chillaxer/playlist-popup-bg.png';
import popupBg4 from '../../assets/images/boxes/chillaxer/sharing-popup-bg.png';
import popupBg5 from '../../assets/images/boxes/chillaxer/meditation-popup-bg.png';
import popupBg6 from '../../assets/images/boxes/chillaxer/fortune-popup-bg.png';

import audio1 from '../../assets/audios/01_Deep_Noticing.mp3';
import audio2 from '../../assets/audios/02_Handling_Strong_Emotions.mp3';
import audio3 from '../../assets/audios/03_Gratitude.mp3';
import audio4 from '../../assets/audios/07_The_Chillaxer_Visualization.mp3';

const Chillaxer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refOpenBox = useRef();
  const refDefault = useRef();

  const { quote, image } = useFetch();

  useEffect(() => {
    let imageList = [
      logo,
      background,
      backgroundOpen,
      palmShadow,
      box,
      boxShadow,
      leaves1,
      leaves2,
      leaves3,
      thing1,
      thing2,
      thing3,
      hover1,
      hover2,
      hover3,
      hover4,
      hover5,
      hover6,
      popupBg1,
      popupBg2,
      popupBg3,
      popupBg4,
      popupBg5,
      popupBg6,
      openBoxImg,
    ];

    cacheImages(imageList);
  }, []);

  const cacheImages = async (srcArray) => {
    const preload = (src) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
      });

    const result = await Promise.all(srcArray.map(preload));
    if (result) setIsLoading(false);
  };

  const handleOpenBox = () => {
    refOpenBox.current.classList.toggle('active');
    refDefault.current.classList.toggle('remove');
  };

  const pauseAllAudio = (activeAudio) => {
    var sounds = document.getElementsByTagName('audio');
    for (var i = 0; i < sounds.length; i++) {
      if (sounds[i].parentElement.className != activeAudio) {
        sounds[i].pause();
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="spinner-div">
          <ClipLoader className="spinner" />
        </div>
      ) : (
        <div className="container-fluid chillaxer-background">
          <div className="row h-100">
            <div className="col-12 palm-shadow px-0" ref={refDefault}>
              <div className="elements">
                <div className="box-shadow"></div>
                <div className="leaves-01"></div>
                <div className="leaves-02"></div>
                <div className="leaves-03"></div>
                <div className="thing-01"></div>
                <div className="thing-02"></div>
                <div className="thing-03"></div>
                <div className="box"></div>
                <div className="logo">
                  <a href="https://www.sweetrush.com" target="_blank" rel="noreferrer">
                    <img src={logo} alt="" className="logo-box" />
                  </a>
                </div>
              </div>
              <a className="button-open" onClick={handleOpenBox}>
                Click to Open
              </a>
            </div>
            <div className="col-12 open-box px-0" ref={refOpenBox}>
              <div className="static-bg">
                <img className="img-bg" src={openBoxImg} alt="" />

                <Popup
                  trigger={
                    <a className="hover1">
                      <img src={hover1} alt="" />
                    </a>
                  }
                  modal
                >
                  {(close) => (
                    <div className="modal-box popup1">
                      <img className="popup-bg" src={popupBg1} alt="" />
                      <button className="close" onClick={close}>
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="content">
                        <p>
                          Nobody appreciates comfort and joy more than you, Chillaxer—and nothing
                          brings joy quite like a favorite comfort food. We hope our sweet and
                          savory specialties nourish you throughout this holiday season and the year
                          to come.
                        </p>
                        <a
                          href="https://rise.articulate.com/share/3EIENGgzPul1rJuPjS7ODCu_Y3lC2A5L"
                          className="modal-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Go to recipes
                        </a>
                      </div>
                    </div>
                  )}
                </Popup>

                <Popup
                  trigger={
                    <a className="hover2">
                      <img src={hover2} alt="" />
                    </a>
                  }
                  modal
                >
                  {(close) => (
                    <div className="modal-box popup2">
                      <img className="popup-bg" src={popupBg2} alt="" />
                      <button className="close" onClick={close}>
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="content">
                        <p>
                          From cartooning to calligraphy to coding, a Chillaxer’s hobbies are as
                          unique and varied as their personality. You’re always on the lookout for a
                          new project, and you never shy away from a creative challenge. Check out
                          our #craftspo to help make the most of your next night in.
                        </p>
                        <a
                          href="https://pin.it/3gLTMOs"
                          className="modal-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          See My Board
                        </a>
                      </div>
                    </div>
                  )}
                </Popup>

                <Popup
                  trigger={
                    <a className="hover3">
                      <img src={hover3} alt="" />
                    </a>
                  }
                  modal
                >
                  {(close) => (
                    <div className="modal-box popup3">
                      <img className="popup-bg" src={popupBg3} alt="" />
                      <button className="close" onClick={close}>
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="content">
                        <p>
                          With its power to uplift and transport, music can be an instant
                          mood-booster. Cue up this curated Chillaxer’s playlist to infuse your
                          commute, workday, or walk with calm and harmony.
                        </p>
                        <a
                          href="https://open.spotify.com/playlist/6y1liQ5Edlk4GN5bd4drc7?si=ienoWRg8SVCxI78NLTMEqQ "
                          className="modal-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Listen
                        </a>
                      </div>
                    </div>
                  )}
                </Popup>

                <Popup
                  trigger={
                    <a className="hover4">
                      <img src={hover4} alt="" />
                    </a>
                  }
                  modal
                >
                  {(close) => (
                    <div className="modal-box popup4">
                      <img className="popup-bg" src={popupBg4} alt="" />
                      <button className="close" onClick={close}>
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="content">
                        <p>
                          Whether it’s for family, friends, or friends we haven’t met, it’s always
                          the perfect time to do good things for others. Your thoughtfulness,
                          Chillaxer, could brighten anyone’s day—and we hope you’ll share the joy!
                          Next time you wrap gifts or bake cookies for someone in need, light up our
                          Instagram feed by sharing a picture or a video and use #goodthings.
                        </p>
                      </div>
                    </div>
                  )}
                </Popup>

                <Popup
                  trigger={
                    <a className="hover5">
                      <img src={hover5} alt="" />
                    </a>
                  }
                  modal
                >
                  {(close) => (
                    <div className="modal-box popup5">
                      <img className="popup-bg" src={popupBg5} alt="" />
                      <button
                        className="close"
                        onClick={() => {
                          close();
                          pauseAllAudio();
                        }}
                      >
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="content">
                        <p>
                          A mindfulness practice reinforces two signature Chillaxer qualities:
                          resilience and empathy. We hope these guided experiences inspire you to
                          take some time to recharge and reflect. Each audio will help you plug into
                          your practice anytime you like.
                        </p>
                        <p>Join us for these four calming sessions:</p>
                        <div className="audio-container container-fluid">
                          <div className="row">
                            <div className="col">
                              <span>Deep Noticing</span>
                              <AudioPlayer
                                src={audio1}
                                className="audio-1"
                                layout="horizontal-reverse"
                                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                onPlay={(e) => {
                                  pauseAllAudio(e.currentTarget.parentElement.className);
                                }}
                              />
                            </div>
                            <div className="col">
                              <span>Handling Strong Emotions</span>
                              <AudioPlayer
                                src={audio2}
                                className="audio-2"
                                layout="horizontal-reverse"
                                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                onPlay={(e) => {
                                  pauseAllAudio(e.currentTarget.parentElement.className);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <span>Gratitude</span>
                              <AudioPlayer
                                src={audio3}
                                className="audio-3"
                                layout="horizontal-reverse"
                                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                onPlay={(e) => {
                                  pauseAllAudio(e.currentTarget.parentElement.className);
                                }}
                              />
                            </div>
                            <div className="col">
                              <span>Visualization</span>
                              <AudioPlayer
                                src={audio4}
                                className="audio-4"
                                layout="horizontal-reverse"
                                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                onPlay={(e) => {
                                  pauseAllAudio(e.currentTarget.parentElement.className);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>

                <Popup
                  trigger={
                    <a className="hover6">
                      <img src={hover6} alt="" />
                    </a>
                  }
                  modal
                >
                  {(close) => (
                    <div className="modal-box popup6">
                      <img className="popup-bg" src={popupBg6} alt="" />
                      <button className="close" onClick={close}>
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="fortune-image">
                        <img src={image.thumbnail} alt="" />
                      </div>
                      <div className="content">
                        <p>{quote.text}</p>
                        <a href={image.full} className="modal-link" download>
                          Download
                        </a>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>

              <Link className="link-container" to={{ pathname: 'typeSelector' }}>
                <button className="other-types-button">check other types</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Chillaxer;
