import React from 'react';
import imgTitle from '../assets/images/turn-me.png';

const TabletPortrait = () => {
  return (
    <div className="container-fluid tablet-portrait-detection">
      <div className="row justify-content-center align-items-center align-content-center message-container">
        <div className="col-11 content d-flex flex-column text-center justify-content-center align-items-center align-content-center">
          <img className="title" src={imgTitle} alt="" />

          <p className="small">Turn to landscape mode for a better experience.</p>
        </div>
      </div>
    </div>
  );
};

export default TabletPortrait;
