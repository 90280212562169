import React from 'react';

export const initialState = { profile: '' };

export const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'update':
      return { profile: action.profile };
    default:
      return state;
  }
};

export const Context = React.createContext();
