import React from 'react';

const Player = (video) => {
  return (
    <video
      className={video.classes}
      key={video.id}
      poster={video.thumbnail}
      autoPlay
      loop
      muted
      preload="auto"
    >
      <source src={video.video} />
    </video>
  );
};

export default Player;
