import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import PreloadImage from 'react-preload-image';
import useFetch from '../../hooks/useFetch';

import bg1 from '../../assets/images/boxes/entertainer/entertainer-hover-bg.jpg';
import bg2 from '../../assets/images/boxes/entertainer/open-box-bg.jpg';
import bg3 from '../../assets/images/boxes/entertainer/sharing-hover.jpg';
import bg4 from '../../assets/images/boxes/entertainer/playlist-hover.jpg';
import bg5 from '../../assets/images/boxes/entertainer/hobbies-hover.jpg';
import bg6 from '../../assets/images/boxes/entertainer/meditation-hover.jpg';
import bg7 from '../../assets/images/boxes/entertainer/recipes-hover.jpg';
import bg8 from '../../assets/images/boxes/entertainer/fortune-cookie-hover.jpg';
import bg9 from '../../assets/images/boxes/entertainer/sharing-popup.jpg';
import bg10 from '../../assets/images/boxes/entertainer/playlist-popup.jpg';
import bg11 from '../../assets/images/boxes/entertainer/hobbies-popup.jpg';
import bg12 from '../../assets/images/boxes/entertainer/meditation-popup.jpg';
import bg13 from '../../assets/images/boxes/entertainer/recipes-popup.jpg';
import bg14 from '../../assets/images/boxes/entertainer/fortune-cookie-popup.jpg';

import logoSR from '../../assets/images/sr-love.svg';

import starOne from '../../assets/images/boxes/entertainer/star01.svg';
import starTwo from '../../assets/images/boxes/entertainer/star02.svg';
import starThree from '../../assets/images/boxes/entertainer/star03.svg';
import closeButtonDefault from '../../assets/images/boxes/entertainer/close-btn.svg';
import closeButtonHover from '../../assets/images/boxes/entertainer/close-btn-hover.svg';
import downloadIcon from '../../assets/images/boxes/entertainer/download-icon.svg';
import imageFrame from '../../assets/images/boxes/entertainer/frame.svg';

import audio1 from '../../assets/audios/01_Deep_Noticing.mp3';
import audio2 from '../../assets/audios/02_Handling_Strong_Emotions.mp3';
import audio3 from '../../assets/audios/03_Gratitude.mp3';
import audio4 from '../../assets/audios/06_The_Entertainer_Seated_Meditation.mp3';

const Entertainer = () => {
  const [inHover, setHover] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [inPopup, setPopup] = useState(false);
  const [currentPopup, setCurrentPopup] = useState('');
  const [currentHover, setCurrentHover] = useState('');

  const { quote, image } = useFetch();

  const handlePopupOpen = (popupClass) => {
    setCurrentPopup(popupClass);
    setPopup(true);
  };

  const handlePopupClose = () => {
    pauseAllAudio();
    setCurrentPopup('');
    setPopup(false);
  };

  const pauseAllAudio = (activeAudio) => {
    var sounds = document.getElementsByTagName('audio');
    for (var i = 0; i < sounds.length; i++) {
      if (sounds[i].parentElement.className != activeAudio) {
        sounds[i].pause();
      }
    }
  };

  return (
    <div
      className={`container-fluid entertainer ${
        inHover && !isOpen ? 'hover' : isOpen ? 'open' : ''
      } ${isOpen && currentHover !== '' && currentPopup === '' ? currentHover : ''} ${
        isOpen && currentPopup !== '' ? currentPopup : ''
      } ${isOpen && inPopup ? 'popup-open' : ''}`}
    >
      <PreloadImage className="preloadImage" src={bg1} />
      <PreloadImage className="preloadImage" src={bg2} />
      <PreloadImage className="preloadImage" src={bg3} />
      <PreloadImage className="preloadImage" src={bg4} />
      <PreloadImage className="preloadImage" src={bg5} />
      <PreloadImage className="preloadImage" src={bg6} />
      <PreloadImage className="preloadImage" src={bg7} />
      <PreloadImage className="preloadImage" src={bg8} />
      <PreloadImage className="preloadImage" src={bg9} />
      <PreloadImage className="preloadImage" src={bg10} />
      <PreloadImage className="preloadImage" src={bg11} />
      <PreloadImage className="preloadImage" src={bg12} />
      <PreloadImage className="preloadImage" src={bg13} />
      <PreloadImage className="preloadImage" src={bg14} />
      <div
        className="clickable-box"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setOpen(true)}
      >
        <div className="popup-close" onClick={() => handlePopupClose()}>
          <img src={closeButtonDefault} alt="close button" className="default" />
          <img src={closeButtonHover} alt="close button" className="hover" />
        </div>
        <div className="stars-container">
          <img src={starOne} alt="" className="starOne star" />
          <img src={starTwo} alt="" className="starTwo star" />
          <img src={starThree} alt="" className="starThree star" />
        </div>
        <div className="boxes-container">
          <div className="row">
            <div
              className="col sharing"
              onMouseEnter={() => setCurrentHover('sharing')}
              onMouseLeave={() => setCurrentHover('')}
              onClick={() => handlePopupOpen('sharing-popup')}
            ></div>
            <div
              className="col playlist"
              onMouseEnter={() => setCurrentHover('playlist')}
              onMouseLeave={() => setCurrentHover('')}
              onClick={() => handlePopupOpen('playlist-popup')}
            ></div>
            <div
              className="col hobbies"
              onMouseEnter={() => setCurrentHover('hobbies')}
              onMouseLeave={() => setCurrentHover('')}
              onClick={() => handlePopupOpen('hobbies-popup')}
            ></div>
          </div>
          <div className="row">
            <div
              className="col meditation"
              onMouseEnter={() => setCurrentHover('meditation')}
              onMouseLeave={() => setCurrentHover('')}
              onClick={() => handlePopupOpen('meditation-popup')}
            ></div>
            <div
              className="col recipes"
              onMouseEnter={() => setCurrentHover('recipes')}
              onMouseLeave={() => setCurrentHover('')}
              onClick={() => handlePopupOpen('recipes-popup')}
            ></div>
            <div
              className="col fortune"
              onMouseEnter={() => setCurrentHover('fortune')}
              onMouseLeave={() => setCurrentHover('')}
              onClick={() => handlePopupOpen('fortune-popup')}
            ></div>
          </div>
        </div>

        <div className="popup-container sharing-content">
          <div className="popup-text">
            Whether it’s for family, friends, or friends we haven’t met, we have infinite
            opportunities to do good things for others. Your open heart, Entertainer, brightens
            everyone’s day—and we want to share in the joy! Next time you check on a lonely neighbor
            or organize a local food drive, light up our Instagram feed by sharing a picture or a
            video and use #goodthings.
          </div>
        </div>

        <div className="popup-container playlist-content">
          <div className="popup-text">
            A great soundtrack puts you in the zone, Entertainer—whether you’re hosting a cookout or
            a conference call. Cue up this curated playlist anytime you want to add energy and
            exuberance to your day.
          </div>
          <a
            href="https://open.spotify.com/playlist/2OdAGqQtzWa2LWEbiz0J1C?si=qfM6rzmVSFO5whzs6xsUxA"
            target="_blank"
            rel="noreferrer"
            className="listen-btn btn"
          >
            listen
          </a>
        </div>

        <div className="popup-container hobbies-content">
          <div className="popup-text">
            Entertainer, you’re the glue that holds your social circle together. You love connecting
            people, whether it’s over a home-cooked meal with your bubble or a Zoom-enabled board
            game. This list of curated activities will help you plan the ultimate night in with your
            closest friends, near and far.
          </div>
          <a
            href="https://pin.it/7tJ8nL7 "
            target="_blank"
            rel="noreferrer"
            className="share-btn btn"
          >
            SEE MY BOARD
          </a>
        </div>

        <div className="popup-container meditation-content">
          <div className="popup-text">
            You’re the perfect host, Entertainer—but you need to recharge, too. A mindfulness
            practice will replenish your famous resilience and empathy. We hope this collection of
            guided experiences helps you hold space for you.
            <br />
            <br /> Join us for these four revitalizing sessions:
          </div>
          <div className="audio-container container-fluid g-0">
            <div className="row">
              <div className="col">
                <span>Deep Noticing</span>
                <AudioPlayer
                  src={audio1}
                  className="audio-1"
                  layout="horizontal-reverse"
                  customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                  customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                  onPlay={(e) => {
                    pauseAllAudio(e.currentTarget.parentElement.className);
                  }}
                />
              </div>
              <div className="col">
                <span>Handling Strong Emotions</span>
                <AudioPlayer
                  src={audio2}
                  className="audio-2"
                  layout="horizontal-reverse"
                  customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                  customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                  onPlay={(e) => {
                    pauseAllAudio(e.currentTarget.parentElement.className);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span>Gratitude</span>
                <AudioPlayer
                  src={audio3}
                  className="audio-3"
                  layout="horizontal-reverse"
                  customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                  customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                  onPlay={(e) => {
                    pauseAllAudio(e.currentTarget.parentElement.className);
                  }}
                />
              </div>
              <div className="col">
                <span>Seated Meditation</span>
                <AudioPlayer
                  src={audio4}
                  className="audio-4"
                  layout="horizontal-reverse"
                  customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                  customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                  onPlay={(e) => {
                    pauseAllAudio(e.currentTarget.parentElement.className);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="popup-container recipes-content">
          <div className="popup-text">
            Entertainer, you’re the definition of comfort and joy. Just like your mouth-watering
            menu, your wit and warmth draw loved ones to you every season. Add some of our sweet and
            savory favorites to your repertoire and share with your bubble.
          </div>
          <a
            href="https://rise.articulate.com/share/3EIENGgzPul1rJuPjS7ODCu_Y3lC2A5L"
            target="_blank"
            rel="noreferrer"
            className="recipes-btn btn"
          >
            GO TO RECIPES
          </a>
        </div>

        <div className="popup-container fortune-content">
          <div className="popup-text">“{quote.text}”</div>
          <div className="fortune-image">
            <div className="thumbnail">
              <img className="thumbnail-img" src={image.thumbnail} alt="" />
              <img className="frame" src={imageFrame} alt="" />
            </div>
          </div>
          <a href={image.full} className="download-btn btn" download>
            <img src={downloadIcon} alt="download icon" /> Download
          </a>
        </div>
      </div>

      <div className="logo-container">
        <a href="https://www.sweetrush.com" target="_blank" rel="noreferrer">
          <img src={logoSR} alt="" className="logo-box" />
        </a>
      </div>
      <Link className="link-container" to={{ pathname: 'typeSelector' }}>
        <button className="other-types-button">check other types</button>
      </Link>
    </div>
  );
};

export default Entertainer;
