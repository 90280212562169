import React, { useContext, useState, useEffect, useRef } from 'react';
import Player from './Player';
import parse from 'html-react-parser';
import { Context } from '../store';
import { Redirect, Link } from 'react-router-dom';
import videoStepOne from '../assets/videos/01_03_010_A.mp4';
import videoStepTwo from '../assets/videos/01_03_010_B.mp4';
import videoStepThree from '../assets/videos/01_03_010_C.mp4';
import videoLastStep from '../assets/videos/01_03_010_D.mp4';

const profiles = [
  {
    class: 'first-step',
    content:
      '<div class="col-xl-6 offset-xl-3 col-8 offset-2 content d-flex flex-column"><div class="title"></div><p>We’re adding some finishing touches.</p><p>A sprig of lavender...</p><p>a cinnamon stick...</p></div>',
    background: videoStepOne,
  },
  {
    class: 'second-step',
    content:
      '<div class="col-xl-6 offset-xl-3 col-8 offset-2 content d-flex flex-column"><div class="title"></div><p>While you wait, why don’t you practice some relaxation techniques?</p></div>',
    background: videoStepTwo,
  },
  {
    class: 'third-step',
    content:
      '<div class="col-10 offset-1 content d-flex flex-column"><div class="content"><div class="item"><span class="spot one"></span>Take a deep breath.</div><div class="item"><span class="spot two"></span>Hold your breath for a count of seven.</div><div class="item"><span class="spot three"></span>Slowly exhale while counting to seven.</div></div></div>',
    background: videoStepThree,
  },
  {
    class: 'last-step',
    background: videoLastStep,
  },
];

const GiftWrapping = () => {
  const { store } = useContext(Context);
  const [index, setIndex] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const refContainer = useRef();

  useEffect(() => {
    let search = store.profile;

    if (search === '') {
      setRedirect(true);
    }

    setTimeout(() => {
      changeScreen(1);

      setTimeout(() => {
        changeScreen(2);

        setTimeout(() => {
          changeScreen(3);
        }, 11000);
      }, 5000);
    }, 5000);
  }, [store.profile]);

  const changeScreen = (index) => {
    const { current } = refContainer;

    if (current !== null) {
      setTimeout(() => {
        current.classList.toggle('changing');
      }, 500);

      setTimeout(() => {
        const next = index;

        if (next < profiles.length) {
          setIndex(next);
        }
      }, 500);

      current.classList.toggle('changing');
    }
  };

  return (
    <>
      {redirect === true ? (
        redirect && <Redirect to={'/'} />
      ) : (
        <div className={`gift-wrapping-container ${profiles[index].class}`} ref={refContainer}>
          <div className="video-container">
            <div className="overlay">
              <Player
                id={profiles[0].class}
                video={profiles[0].background}
                thumbnail=""
                classes={`videoTag ${index === 0 ? 'active' : ''}`}
              />
              <Player
                id={profiles[1].class}
                video={profiles[1].background}
                thumbnail=""
                classes={`videoTag ${index === 1 ? 'active' : ''}`}
              />
              <Player
                id={profiles[2].class}
                video={profiles[2].background}
                thumbnail=""
                classes={`videoTag ${index === 2 ? 'active' : ''}`}
              />
              <Player
                id={profiles[3].class}
                video={profiles[3].background}
                thumbnail=""
                classes={`videoTag ${index === 3 ? 'active' : ''}`}
              />
            </div>
          </div>
          <div className="video-content container-fluid">
            <div className="row">
              {index < profiles.length - 1 ? (
                parse(profiles[index].content)
              ) : (
                <div className="col-6 offset-6 content d-flex flex-column">
                  <p>When you’re ready, click</p>
                  <Link className="receive-gift" to="/results">
                    receive my gift
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GiftWrapping;
