import React, { useState, useRef, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Context } from '../store';
import Player from './Player';

const Quiz = () => {
  const questions = [
    {
      background: require('../assets/videos/01_02_010-option_03.mp4').default,
      questionText: 'I’ve just been handed a hot cup of coffee, so I:',
      answerOptions: [
        { answerText: 'Wait for it to cool down.', value: 'A' },
        { answerText: 'Dive straight in.', value: 'B' },
      ],
    },
    {
      background: require('../assets/videos/01_02_020-option_01.mp4').default,
      questionText: 'I like to enjoy wildlife:',
      answerOptions: [
        { answerText: 'Up close and personal.', value: 'D' },
        { answerText: 'In HD with surround sound.', value: 'C' },
      ],
    },
    {
      background: require('../assets/videos/01_02_030-option_03.mp4').default,
      questionText: 'Chocolate is best when it’s:',
      answerOptions: [
        { answerText: 'Straight from the fridge.', value: 'C' },
        { answerText: 'Smushed with a toasted marshmallow between graham crackers.', value: 'D' },
      ],
    },
    {
      background: require('../assets/videos/01_02_040-option_03.mp4').default,
      questionText: 'I join an online meeting, and no one is talking. So I:',
      answerOptions: [
        { answerText: 'Introduce myself and ask how everyone is doing.', value: 'B' },
        {
          answerText:
            'Put myself on mute, check what’s in the fridge, and wait for things to get started.',
          value: 'A',
        },
      ],
    },
    {
      background: require('../assets/videos/01_02_050-option_01.mp4').default,
      questionText: 'I would love to spend the day with:',
      answerOptions: [
        { answerText: 'Marie Kondo', value: 'C' },
        { answerText: 'David Attenborough', value: 'D' },
      ],
    },
    {
      background: require('../assets/videos/01_02_060-option_01.mp4').default,
      questionText: 'A perfect night in is:',
      answerOptions: [
        { answerText: 'Group chat on, Netflix off.', value: 'B' },
        { answerText: 'Netflix on, phone off.', value: 'A' },
      ],
    },
    {
      background: require('../assets/videos/01_02_070-option_03.mp4').default,
      questionText: 'Which appetizer?',
      answerOptions: [
        { answerText: 'A bowl of soup.', value: 'A' },
        { answerText: 'A sharing platter.', value: 'B' },
      ],
    },
    {
      background: require('../assets/videos/01_02_080-option_02.mp4').default,
      questionText: 'I’d rather sleep under:',
      answerOptions: [
        { answerText: 'The stars.', value: 'D' },
        { answerText: 'A big fluffy duvet.', value: 'C' },
      ],
    },
    {
      background: require('../assets/videos/01_02_090-option_02.mp4').default,
      questionText: 'At a party, you will find me:',
      answerOptions: [
        { answerText: 'Hiding in the kitchen.', value: 'A' },
        { answerText: 'Mingling, baby!', value: 'B' },
      ],
    },
    {
      background: require('../assets/videos/01_02_100-option_02.mp4').default,
      questionText: 'I like to get lost:',
      answerOptions: [
        { answerText: 'In a new book.', value: 'C' },
        { answerText: 'In a new city.', value: 'D' },
      ],
    },
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState([0, 0, 0, 0]);
  const [redirect, setRedirect] = useState(false);

  const refContainer = useRef();
  const refBullets = useRef([]);

  const { dispatch } = useContext(Context);

  const handleAnswerOptionClick = (value) => {
    updateScore(value);
    if (currentQuestion + 1 < questions.length) {
      const { current } = refContainer;

      current.classList.toggle('changing');

      let previousQuestion = currentQuestion;

      setTimeout(() => {
        const nextQuestion = currentQuestion + 1;

        if (nextQuestion < questions.length) {
          setCurrentQuestion(nextQuestion);
        }

        setTimeout(() => {
          current.classList.toggle('changing');

          refBullets.current[previousQuestion].classList.add('visited');
        }, 500);
      }, 500);
    } else {
      calculateResult();
      setRedirect(true);
    }
  };

  const updateScore = (value) => {
    let newScore = score;

    switch (value) {
      case 'A':
        newScore[0]++;
        break;
      case 'B':
        newScore[1]++;
        break;
      case 'C':
        newScore[2]++;
        break;
      default:
        newScore[3]++;
        break;
    }

    setScore(newScore);
  };

  const calculateResult = () => {
    let results = score
      .map((item, index) => (item >= 3 ? { item, index } : null))
      .filter((x) => x !== null);
    let profile = '';

    if (results[0].index === 0 && results[1].index === 2) {
      profile = 'chillaxer';
    } else if (results[0].index === 0 && results[1].index === 3) {
      profile = 'wanderer';
    } else if (results[0].index === 1 && results[1].index === 2) {
      profile = 'entertainer';
    } else {
      profile = 'adventurer';
    }
    dispatch({ type: 'update', profile: profile });
  };

  const handleReload = () => {
    const { current } = refContainer;

    current.classList.toggle('changing');

    setTimeout(() => {
      refBullets.current.map((item, i) => {
        item.classList.remove('visited');
        item.classList.remove('active');

        setCurrentQuestion(0);
        setScore([0, 0, 0, 0]);

        dispatch({ type: 'reset' });
      });
    }, 500);

    setTimeout(() => {
      current.classList.toggle('changing');
      refBullets.current[0].classList.add('active');
    }, 500);
  };

  return (
    <div className="container-fluid assessment" ref={refContainer}>
      <div className="row h-100 justify-content-center align-items-center align-content-center">
        <div className="col-12 overlay">
          <Player
            id={0}
            video={questions[0].background}
            thumbnail=""
            classes={`assessment-background ${currentQuestion === 0 ? 'active' : ''}`}
          />
          <Player
            id={1}
            video={questions[1].background}
            thumbnail=""
            classes={`assessment-background ${currentQuestion === 1 ? 'active' : ''}`}
          />
          <Player
            id={2}
            video={questions[2].background}
            thumbnail=""
            classes={`assessment-background ${currentQuestion === 2 ? 'active' : ''}`}
          />
          <Player
            id={3}
            video={questions[3].background}
            thumbnail=""
            classes={`assessment-background ${currentQuestion === 3 ? 'active' : ''}`}
          />
          <Player
            id={4}
            video={questions[4].background}
            thumbnail=""
            classes={`assessment-background ${currentQuestion === 4 ? 'active' : ''}`}
          />
          <Player
            id={5}
            video={questions[5].background}
            thumbnail=""
            classes={`assessment-background ${currentQuestion === 5 ? 'active' : ''}`}
          />
          <Player
            id={6}
            video={questions[6].background}
            thumbnail=""
            classes={`assessment-background ${currentQuestion === 6 ? 'active' : ''}`}
          />
          <Player
            id={7}
            video={questions[7].background}
            thumbnail=""
            classes={`assessment-background ${currentQuestion === 7 ? 'active' : ''}`}
          />
          <Player
            id={8}
            video={questions[8].background}
            thumbnail=""
            classes={`assessment-background ${currentQuestion === 8 ? 'active' : ''}`}
          />
          <Player
            id={9}
            video={questions[9].background}
            thumbnail=""
            classes={`assessment-background ${currentQuestion === 9 ? 'active' : ''}`}
          />
        </div>
        <div className="col-8 content d-flex flex-column text-center justify-content-center align-items-center align-content-center">
          <div className="question-section">
            <div className="question-text">{questions[currentQuestion].questionText}</div>
          </div>

          <div className="answer-section">
            {questions[currentQuestion].answerOptions.map((answerOption, index) => (
              <button key={index} onClick={() => handleAnswerOptionClick(answerOption.value)}>
                {answerOption.answerText}
              </button>
            ))}
          </div>

          <ul className="progress-bullets list-inline">
            {questions.map((item, index) => (
              <li
                key={index}
                ref={(el) => (refBullets.current[index] = el)}
                className={`bullet list-inline-item ${index === currentQuestion ? 'active' : ''}`}
              ></li>
            ))}
          </ul>
          <div className="prueba">
            <button onClick={() => handleReload()}>Restart test</button>
          </div>
        </div>
      </div>

      {redirect && <Redirect to={'/giftWrapping'} />}
    </div>
  );
};

export default Quiz;
