import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { Redirect, Link } from 'react-router-dom';
import { Context } from '../store';
import { Helmet } from 'react-helmet';

import adventurerPDF from '../assets/pdfs/adventurer.pdf';
import chillaxerPDF from '../assets/pdfs/chillaxer.pdf';
import entertainerPDF from '../assets/pdfs/entertainer.pdf';
import wandererPDF from '../assets/pdfs/wanderer.pdf';

import adventurerShareImg from '../assets/images/share/SweetRush-Feel-Good-Gift-Adventurer.jpg';
import chillaxerShareImg from '../assets/images/share/SweetRush-Feel-Good-Gift-Chillaxer.jpg';
import entertainerShareImg from '../assets/images/share/SweetRush-Feel-Good-Gift-Entertainer.jpg';
import wandererShareImg from '../assets/images/share/SweetRush-Feel-Good-Gift-Wanderer.jpg';

const profiles = [
  {
    class: 'chillaxer',
    title: 'Cheers to the Chillaxers!',
    content:
      '<p>It’s not that you don’t like spending time with other people, darling Chillaxer. It’s just that you need some quality “me time” to recharge your batteries. And where better to do that than from the comfort of your own home?</p><p>Don’t let the name fool you: Chillaxers are complex creatures. While it’s true that you’re often found unwinding with a good book or cooking up some delights in the kitchen while listening to your favorite podcast, you’re just as likely to be precariously perched on a ladder, taking on an ambitious home improvement project.</p><p>One thing is for certain, Chillaxer: your home is your castle, and you reign supreme!</p>',
    preLink: 'Our gift for you is party-for-one in a box!',
    link: '/chillaxer',
    textForShare: 'I am a chillaxer!',
    seo: {
      title: 'Chillaxer',
      description:
        'Here’s a personalized, hand-crafted thank-you gift to bring a smile to your heart and warm your soul.',
      image: chillaxerShareImg,
    },
  },
  {
    class: 'wanderer',
    title: 'Welcome, Wanderers, wherever you are!',
    content:
      '<p>The world is your oyster, and solitude is your energy bar. Yes, your idea of relaxation, dear Wanderer, is spending some quality alone time in the great outdoors.</p><p>Whether you’ve already embraced the nomadic life and are living out of a suitcase or are keeping it local and exploring what’s around you—including that new coffee shop on Main Street—you’re in <i>your element</i> when left alone in <i>the elements</i>.</p>',
    preLink: ' Our gift for you will scratch your wanderlust itch!',
    link: '/wanderer',
    textForShare: 'I am a wanderer!',
    seo: {
      title: 'Wanderer',
      description:
        'Here’s a personalized, hand-crafted thank-you gift to bring a smile to your heart and warm your soul.',
      image: wandererShareImg,
    },
  },
  {
    class: 'entertainer',
    title: 'Can we get an encore for the Entertainer!',
    content:
      '<p>You’re a ball of energy around others, dear Entertainer, and love nothing more than getting together with friends, family, and loved ones—preferably in the comfort of your homes—for a fun-filled time.</p><p>Whether it’s hosting a virtual dinner party for friends, joining family online for game night, or an outdoor fireside chat with your neighbors, you can always be counted on to show up and make a big impression.</p>',
    preLink: 'Our gift for you will make you the host(ess) with the most(ess)!',
    link: '/entertainer',
    textForShare: 'I am an entertainer!',
    seo: {
      title: 'Entertainer',
      description:
        'Here’s a personalized, hand-crafted thank-you gift to bring a smile to your heart and warm your soul.',
      image: entertainerShareImg,
    },
  },
  {
    class: 'adventurer',
    title: 'Ahoy there, Adventurer!',
    content:
      '<p>Most people like to relax by kicking back and taking things easy—but not you, dear Adventurer! Sitting still just isn’t your thing. The idea of being cooped up indoors all by yourself makes you want to—quite literally—run for the hills!</p><p>You’re at your happiest and best in the company of others and in a place where you can lose yourself. Whether that’s hunting down a bargain at the nearest farmers’ market, taking in a drive-in movie, going on a hike with your bubble, or meeting up for a socially-distanced scavenger hunt, you have a thirst for adventure and want to share your experiences with people you love!</p>',
    preLink: 'Our gift for you will wet your appetite for adventure!',
    link: '/adventurer',
    textForShare: 'I am an adventurer!',
    seo: {
      title: 'Adventurer',
      description:
        'Here’s a personalized, hand-crafted thank-you gift to bring a smile to your heart and warm your soul.',
      image: adventurerShareImg,
    },
  },
];

const ResultsSummary = (props) => {
  const { store } = useContext(Context);
  const [current, setCurrent] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const [fromSelector, setfromSelector] = useState(false);

  useEffect(() => {
    let search = store.profile;

    if (typeof props.location.state !== 'undefined') {
      if (typeof props.location.state.data.selector !== 'undefined') {
        setfromSelector(props.location.state.data.selector);
      }
    }

    if (typeof props.location.state !== 'undefined') {
      search = props.location.state.data.class;
    }

    if (typeof props.profile !== 'undefined') {
      search = props.profile;
      setfromSelector(true);
    }

    profiles.map((item, index) => {
      if (item.class === search) {
        setCurrent(item);
      }

      if (current === null) {
        setRedirect(true);
      }
    });
  }, [current, props.location.state, props.profile, store.profile]);

  return (
    <>
      {current === null ? (
        redirect && <Redirect to={'/'} />
      ) : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Feel Good Gift | {current.seo.title}</title>
            <link rel="canonical" href={`https://feelgood.sweetrush.com/result-${current.class}`} />
            <meta name="title" content={`Feel Good Gift | ${current.seo.title}`} />
            <meta name="description" content={current.seo.description} />

            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://feelgood.sweetrush.com/result-${current.class}`}
            />
            <meta property="og:title" content={`Feel Good Gift | ${current.seo.title}`} />
            <meta property="og:description" content={current.seo.description} />
            <meta
              property="og:image"
              content={`https://feelgood.sweetrush.com${current.seo.image}`}
            />

            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content={`https://feelgood.sweetrush.com/result-${current.class}`}
            />
            <meta property="twitter:title" content={`Feel Good Gift | ${current.seo.title}`} />
            <meta property="twitter:description" content={current.seo.description} />
            <meta
              property="twitter:image"
              content={`https://feelgood.sweetrush.com${current.seo.image}`}
            />
          </Helmet>

          <div className={`container-fluid result-container bg-${current.class}`}>
            <div className="row justify-content-center align-items-center align-content-center message-container h-100">
              <div className="col-6 offset-6 content d-flex flex-column align-items-right">
                <div className="result-title">{current.title}</div>
                <div className="result-content">
                  {parse(current.content)}

                  <p className="bottom-text">{parse(current.preLink)}</p>

                  <div className="bottom-section">
                    <div className="buttons">
                      <Link className="button-result button-inverted" to={current.link}>
                        {fromSelector ? 'see this box' : 'unwrap my gift'}
                      </Link>
                      <Link className="button-result" to="/assessment">
                        take the test again
                      </Link>
                      <Link className="button-result" to="/typeSelector">
                        check the other types
                      </Link>
                    </div>

                    <div className="social-container">
                      Share your downtime personality type on social media using the hashtag
                      <span> #unwindwithSR</span>
                      {fromSelector ? (
                        <></>
                      ) : (
                        <div className="social-links">
                          <FacebookShareButton
                            url={`https://feelgood.sweetrush.com/result-${current.class}`}
                          >
                            <a className="fb-link"></a>
                          </FacebookShareButton>
                          <TwitterShareButton
                            title={current.textForShare}
                            hashtags={['unwindwithSR']}
                            url={`https://feelgood.sweetrush.com/result-${current.class}`}
                          >
                            <a className="tw-link"></a>
                          </TwitterShareButton>
                        </div>
                      )}
                    </div>
                    <div className="download-link">
                      <a
                        href={
                          current.class === 'adventurer'
                            ? adventurerPDF
                            : current.class === 'chillaxer'
                            ? chillaxerPDF
                            : current.class === 'entertainer'
                            ? entertainerPDF
                            : wandererPDF
                        }
                        download={`${current.class}.pdf`}
                        className="download-link"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

ResultsSummary.propTypes = {
  props: PropTypes.object,
  location: PropTypes.object,
  profile: PropTypes.string,
};

export default ResultsSummary;
