import { useState, useEffect } from 'react';

const useFetch = () => {
  const [quotes] = useState([
    {
      text: "Breathe it's just a bad day, not a bad life",
      type: 'Hope',
    },
    {
      text: 'Focus on you hopes, not on your fears',
      type: 'Hope',
    },
    {
      text: 'Every Day May Not Be Good But There Is Something Good In Every Day',
      type: 'Hope',
    },
    {
      text: 'Storms make trees take deeper roots.',
      type: 'Hope',
    },
    {
      text: 'Act as if what you do makes a difference. It does.',
      type: 'Motivation',
    },
    {
      text: 'Better an oops than a what if...',
      type: 'Motivation',
    },
    {
      text: "Pro tip: When you're not sure, flip a coin because while the coin is in the air, you realize which one you're actually hoping for.",
      type: 'Motivation',
    },
    {
      text: 'A smile is the prettiest thing you can wear',
      type: 'Motivation',
    },
    {
      text: 'Live your life and forget your age',
      type: 'Motivation',
    },
    {
      text: 'Sometimes you win, always you learn. ',
      type: 'Motivation',
    },
    {
      text: 'All great ideas are dangerous',
      type: 'Motivation',
      author: 'Oscar Wilde',
    },
    {
      text: 'You only live once... Lick the bowl!',
      type: 'Funny',
    },
    {
      text: 'Why be moody when you can shake your booty',
      type: 'Funny',
    },
    {
      text: 'Life is short. Smile while you have teeth',
      type: 'Funny',
    },
    {
      text: 'You only need to find yourself, everything else can be googled.',
      type: 'Funny',
    },
    {
      text: 'The more you weigh, the harder you are to kidnap. Stay safe. Eat cake.',
      type: 'Funny',
    },
    {
      text: 'If you see someone without a smile, give them one of your.',
      author: 'Dolly Parton',
      type: 'Reflection',
    },
    {
      text: "Enjoy life, there's plenty of time to be dead",
      author: 'Hans Christian Andersen',
      type: 'Reflection',
    },
    {
      text: 'Disconnect to connect',
      type: 'Reflection',
    },
    {
      text: 'You find beauty in ordinary things, do not lose this ability.',
      type: 'Reflection',
    },
    {
      text: 'Happy mind, happy life',
      type: 'Reflection',
    },
    {
      text: 'There is no elevator to success. You have to take the stairs',
      type: 'Reflection',
    },
    {
      text: 'Be a fountain, not a drain',
      type: 'Reflection',
    },
    {
      text: "Don't adapt to the energy in the room, INFLUENCE the energy in the room",
      type: 'Reflection',
    },
  ]);

  const [images] = useState([
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-January.jpg').default,
      full: require('../assets/images/fortune/full/IG-January.jpg').default,
    },
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-February.jpg').default,
      full: require('../assets/images/fortune/full/IG-February.jpg').default,
    },
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-March.jpg').default,
      full: require('../assets/images/fortune/full/IG-March.jpg').default,
    },
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-April.png').default,
      full: require('../assets/images/fortune/full/IG-April.png').default,
    },
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-May.jpg').default,
      full: require('../assets/images/fortune/full/IG-May.jpg').default,
    },
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-June.jpg').default,
      full: require('../assets/images/fortune/full/IG-June.jpg').default,
    },
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-July.jpg').default,
      full: require('../assets/images/fortune/full/IG-July.jpg').default,
    },
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-August.png').default,
      full: require('../assets/images/fortune/full/IG-August.png').default,
    },
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-September.png').default,
      full: require('../assets/images/fortune/full/IG-September.png').default,
    },
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-October.jpg').default,
      full: require('../assets/images/fortune/full/IG-October.jpg').default,
    },
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-November.png').default,
      full: require('../assets/images/fortune/full/IG-November.png').default,
    },
    {
      thumbnail: require('../assets/images/fortune/thumbnail/IG-December.png').default,
      full: require('../assets/images/fortune/full/IG-December.png').default,
    },
  ]);
  const [quote, setQuote] = useState(quotes[0]);
  const [image, setImage] = useState(images[0]);

  useEffect(() => {
    const getRandom = () => {
      let newQuote = Math.floor(Math.random() * quotes.length);
      let newImage = Math.floor(Math.random() * images.length);
      setQuote(quotes[newQuote]);
      setImage(images[newImage]);
    };

    getRandom();
  }, [images, quotes]);

  return { quote, image };
};

export default useFetch;
