import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';

import ClipLoader from 'react-spinners/ClipLoader';

import titleImg from '../../assets/images/boxes/wanderer/title_wanderer.svg';

import background from '../../assets/images/boxes/wanderer/background.png';

import audio1 from '../../assets/audios/01_Deep_Noticing.mp3';
import audio2 from '../../assets/audios/02_Handling_Strong_Emotions.mp3';
import audio3 from '../../assets/audios/03_Gratitude.mp3';
import audio4 from '../../assets/audios/05_The_Wanderer_Mindful_Walk.mp3';

import default1 from '../../assets/images/boxes/wanderer/default1.png';
import default2 from '../../assets/images/boxes/wanderer/default2.png';
import default3 from '../../assets/images/boxes/wanderer/default3.png';
import default4 from '../../assets/images/boxes/wanderer/default4.png';
import default5 from '../../assets/images/boxes/wanderer/default5.png';
import default6 from '../../assets/images/boxes/wanderer/default6.png';

import open1 from '../../assets/images/boxes/wanderer/open1.png';
import open2 from '../../assets/images/boxes/wanderer/open2.png';
import open3 from '../../assets/images/boxes/wanderer/open3.png';
import open4 from '../../assets/images/boxes/wanderer/open4.png';
import open5 from '../../assets/images/boxes/wanderer/open5.png';
import open6 from '../../assets/images/boxes/wanderer/open6.png';

import hover1 from '../../assets/images/boxes/wanderer/hover1.png';
import hover2 from '../../assets/images/boxes/wanderer/hover2.png';
import hover3 from '../../assets/images/boxes/wanderer/hover3.png';
import hover4 from '../../assets/images/boxes/wanderer/hover4.png';
import hover5 from '../../assets/images/boxes/wanderer/hover5.png';
import hover6 from '../../assets/images/boxes/wanderer/hover6.png';

import thing1 from '../../assets/images/boxes/wanderer/thing1.png';
import thing2 from '../../assets/images/boxes/wanderer/thing2.png';
import thing3 from '../../assets/images/boxes/wanderer/thing3.png';

import popupClose from '../../assets/images/boxes/wanderer/wan_close_default_x.png';
import logoSR from '../../assets/images/sr-love.svg';

import popupBg1 from '../../assets/images/boxes/wanderer/popup_01_graphic.png';
import popupBg2 from '../../assets/images/boxes/wanderer/popup_02_graphic.png';
import popupBg3 from '../../assets/images/boxes/wanderer/popup_03_graphic.png';
import popupBg4 from '../../assets/images/boxes/wanderer/popup_04_graphic.png';
import popupBg5 from '../../assets/images/boxes/wanderer/popup_05_graphic.png';
import popupBg6 from '../../assets/images/boxes/wanderer/popup_06_graphic.png';

import useFetch from '../../hooks/useFetch';

const Wanderer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [openBox, setOpenBox] = useState(false);
  const overlayStyle = { background: 'rgba(2, 0, 98, 0.7)' };

  const { quote, image } = useFetch();

  const boxToOpen = () => {
    setOpenBox(true);
  };

  const boxToClose = () => {
    setOpenBox(false);
  };

  useEffect(() => {
    const imageList = [
      background,
      default1,
      default2,
      default3,
      default4,
      default5,
      default6,
      open1,
      open2,
      open3,
      open4,
      open5,
      open6,
      hover1,
      hover2,
      hover3,
      hover4,
      hover5,
      hover6,
      popupBg1,
      popupBg2,
      popupBg3,
      popupBg4,
      popupBg5,
      popupBg6,
      logoSR,
    ];

    cacheImages(imageList);
  }, []);

  const cacheImages = async (srcArray) => {
    const preload = (src) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
      });

    const result = await Promise.all(srcArray.map(preload));
    if (result) setIsLoading(false);
  };

  const pauseAllAudio = (activeAudio) => {
    var sounds = document.getElementsByTagName('audio');
    for (var i = 0; i < sounds.length; i++) {
      if (sounds[i].parentElement.className != activeAudio) {
        sounds[i].pause();
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="spinner-div">
          <ClipLoader className="spinner" />
        </div>
      ) : (
        <div className={`container-fluid wanderer-background ${openBox ? 'open' : ''}`}>
          <div className="row h-100">
            <img src={titleImg} alt="" className={`wanderer-title ${openBox ? 'go-down' : ''}`} />
            <div className="open-box" onClick={boxToOpen}>
              OPEN THE BOX
            </div>
            <div className={`close-box ${openBox ? 'box-open ' : ''}`} onClick={boxToClose}>
              CLOSE THE BOX
            </div>
            <div className={`wanderer-box ${openBox ? 'go-down' : ''}`}>
              <div className="sections section-1">
                <img className="open" src={open1} alt="" />
                <Popup
                  trigger={
                    <a className="hover1">
                      <img className="hover" src={hover1} alt="" />
                    </a>
                  }
                  overlayStyle={overlayStyle}
                  modal
                >
                  {(close) => (
                    <div className="modal-wanderer popup1">
                      <img className="popup-bg img-fluid" src={popupBg1} alt="" />
                      <button className="close" onClick={close}>
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="title">
                        <h2>Recipes</h2>
                      </div>
                      <div className="content">
                        <p>
                          You’re a breath of fresh air, Wanderer, but you need fuel for your
                          explorations. Whether your solo expeditions find you enjoying the bracing
                          winter air or the balmy tropics, these sweet and savory specialties will
                          nourish you all season long.
                        </p>
                      </div>
                      <div className="footer">
                        <a
                          href="https://rise.articulate.com/share/3EIENGgzPul1rJuPjS7ODCu_Y3lC2A5L"
                          className="modal-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Go to recipes
                        </a>
                      </div>
                    </div>
                  )}
                </Popup>
                <img className={`default ${openBox ? 'box-open' : ''}`} src={default1} alt="" />
              </div>

              <div className="sections section-2">
                <img className={`default ${openBox ? 'box-open' : ''}`} src={default2} alt="" />
                <img className="open" src={open2} alt="" />
                <Popup
                  trigger={
                    <a className="hover2">
                      <img className="hover" src={hover2} alt="" />
                    </a>
                  }
                  overlayStyle={overlayStyle}
                  modal
                >
                  {(close) => (
                    <div className="modal-wanderer popup2">
                      <img className="popup-bg img-fluid" src={popupBg2} alt="" />
                      <button className="close" onClick={close}>
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="title">
                        <h2>Hobbies &amp; Interests</h2>
                      </div>
                      <div className="content">
                        <p>
                          From solo hikes to planes, trains, and automobiles, a Wanderer is happiest
                          in motion. You travel light, but you create a ton of memories. These
                          activities will help you connect with your surroundings without weighing
                          down your backpack.
                        </p>
                      </div>
                      <div className="footer">
                        <a
                          href="https://pin.it/5SgL2HF"
                          className="modal-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          See my board
                        </a>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>

              <div className="sections section-3">
                <img className={`default ${openBox ? 'box-open' : ''}`} src={default3} alt="" />
                <img className="open" src={open3} alt="" />
                <Popup
                  trigger={
                    <a className="hover3">
                      <img className="hover" src={hover3} alt="" />
                    </a>
                  }
                  overlayStyle={overlayStyle}
                  modal
                >
                  {(close) => (
                    <div className="modal-wanderer popup3">
                      <img className="popup-bg img-fluid" src={popupBg3} alt="" />
                      <button
                        className="close"
                        onClick={() => {
                          close();
                          pauseAllAudio();
                        }}
                      >
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="title">
                        <h2>Meditation</h2>
                      </div>
                      <div className="content">
                        <p>
                          A mindfulness practice builds resilience and empathy, qualities Wanderers
                          find in their solo explorations. We hope these guided experiences help you
                          recharge and reflect, indoors and out. Each lives in a downloadable audio
                          file so you can plug into your practice from anywhere in the world.
                        </p>
                      </div>
                      <div className="footer">
                        <div className="audio-container container-fluid">
                          <div className="row">
                            <div className="col-3">
                              <span>Deep Noticing</span>
                              <AudioPlayer
                                src={audio1}
                                className="audio-1"
                                layout="horizontal-reverse"
                                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                onPlay={(e) => {
                                  pauseAllAudio(e.currentTarget.parentElement.className);
                                }}
                              />
                            </div>
                            <div className="col-3">
                              <span>Handling Strong Emotions</span>
                              <AudioPlayer
                                src={audio2}
                                className="audio-2"
                                layout="horizontal-reverse"
                                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                onPlay={(e) => {
                                  pauseAllAudio(e.currentTarget.parentElement.className);
                                }}
                              />
                            </div>
                            <div className="col-3">
                              <span>Gratitude</span>
                              <AudioPlayer
                                src={audio3}
                                className="audio-3"
                                layout="horizontal-reverse"
                                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                onPlay={(e) => {
                                  pauseAllAudio(e.currentTarget.parentElement.className);
                                }}
                              />
                            </div>
                            <div className="col-3">
                              <span>Mindful Walk</span>
                              <AudioPlayer
                                src={audio4}
                                className="audio-4"
                                layout="horizontal-reverse"
                                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                onPlay={(e) => {
                                  pauseAllAudio(e.currentTarget.parentElement.className);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>

              <div className="sections section-4">
                <img className={`default ${openBox ? 'box-open' : ''}`} src={default4} alt="" />
                <img className="open" src={open4} alt="" />
                <Popup
                  trigger={
                    <a className="hover4">
                      <img className="hover" src={hover4} alt="" />
                    </a>
                  }
                  overlayStyle={overlayStyle}
                  modal
                >
                  {(close) => (
                    <div className="modal-wanderer popup4">
                      <img className="popup-bg img-fluid" src={popupBg4} alt="" />
                      <button className="close" onClick={close}>
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="title">
                        <h2>Fortune Cookie</h2>
                      </div>
                      <div className="content">
                        <p>{quote.text}</p>
                      </div>
                      <div className="fortune-image">
                        <div className="thumbnail">
                          <img src={image.thumbnail} alt="" />
                        </div>
                      </div>
                      <div className="footer">
                        <a href={image.full} className="download-link" download>
                          Download
                        </a>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>

              <div className="sections section-5">
                <img className={`default ${openBox ? 'box-open' : ''}`} src={default5} alt="" />
                <img className="open" src={open5} alt="" />
                <Popup
                  trigger={
                    <a className="hover5">
                      <img className="hover" src={hover5} alt="" />
                    </a>
                  }
                  overlayStyle={overlayStyle}
                  modal
                >
                  {(close) => (
                    <div className="modal-wanderer popup5">
                      <img className="popup-bg img-fluid" src={popupBg5} alt="" />
                      <button className="close" onClick={close}>
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="title">
                        <h2>Sharing &amp; Caring</h2>
                      </div>
                      <div className="content">
                        <p>
                          Whether it’s for family, friends, or friends we haven’t met, we have
                          infinite opportunities to do good things for others. Your connection with
                          your surroundings, Wanderer, makes the world a better place for everyone!
                          Next time you support a local cause or chase down a stray wrapper, light
                          up our Instagram feed with a photo using our #goodthings hashtag.
                        </p>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>

              <div className="sections section-6">
                <img className={`default ${openBox ? 'box-open' : ''}`} src={default6} alt="" />
                <img className="open" src={open6} alt="" />
                <Popup
                  trigger={
                    <a className="hover6">
                      <img className="hover" src={hover6} alt="" />
                    </a>
                  }
                  overlayStyle={overlayStyle}
                  modal
                >
                  {(close) => (
                    <div className="modal-wanderer popup6">
                      <img className="popup-bg img-fluid" src={popupBg6} alt="" />
                      <button className="close" onClick={close}>
                        <img src={popupClose} alt="" />
                      </button>
                      <div className="title">
                        <h2>Playlist</h2>
                      </div>
                      <div className="content">
                        <p>
                          With its curiosity and energy, music can be the perfect companion for the
                          Wanderer’s soul. Cue up this curated playlist to infuse any journey—from
                          routine to riveting—with a sense of adventure.
                        </p>
                      </div>
                      <div className="footer">
                        <a
                          href="https://open.spotify.com/playlist/0Aso7P44ifTdExBaMLPtzI?si=Wp1mzO0pSyiP9ipX4cg5Jw"
                          className="modal-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Listen
                        </a>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>
            </div>
            <div className="thing-2 px-0">
              <img src={thing2} alt="" className={`${openBox ? 'go-right' : ''}`} />
            </div>
            <div className="thing-3 px-0">
              <img src={thing3} alt="" className={`${openBox ? 'go-left' : ''}`} />
            </div>
            <div className="thing-1 px-0">
              <img src={thing1} alt="" className={`${openBox ? 'go-down' : ''}`} />
            </div>

            <div className="logo-container">
              <a href="https://www.sweetrush.com" target="_blank" rel="noreferrer">
                <img src={logoSR} alt="" className="logo-box" />
              </a>
            </div>
          </div>
          <Link className="link-container" to={{ pathname: 'typeSelector' }}>
            <button className="other-types-button">check other types</button>
          </Link>
        </div>
      )}
    </>
  );
};

export default Wanderer;
