import React, { useReducer, useState, useEffect } from 'react';
import Media from 'react-media';
import Intro from './components/Intro';
import Adventurer from './components/boxes//Adventurer';
import Mobile from './components/Mobile';
import TabletPortrait from './components/TabletPortrait';
import Quiz from './components/Quiz';
import ResultsSummary from './components/ResultsSummary';
import TypeSelector from './components/TypeSelector';
import GiftWrapping from './components/GiftWrapping';
import Entertainer from './components/boxes/EntertainerBox';
import Chillaxer from './components/boxes/ChillaxerBox';
import Wanderer from './components/boxes/WandererBox';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { PageTransition } from '@steveeeie/react-page-transition';
import { Helmet } from 'react-helmet';

import { Context, initialState, reducer } from './store';
import shareImg from './assets/images/share/SweetRush-Feel-Good-Gift.jpg';

function App() {
  const location = useLocation();
  const animations = [
    'moveToLeftFromRight',
    'moveToRightFromLeft',
    'moveToTopFromBottom',
    'moveToBottomFromTop',
    'fadeFromRight',
    'fadeFromLeft',
    'fadeFromBottom',
    'fadeFromTop',
    'fadeLeftFadeRight',
    'fadeRightFadeLeft',
    'fadeTopFadeBottom',
    'fadeBottomFadeTop',
    'scaleDownFromRight',
    'scaleDownFromLeft',
    'scaleDownFromBottom',
    'scaleDownFromTop',
    'scaleDownScaleDown',
    'scaleUpScaleUp',
    'moveToLeftScaleUp',
    'moveToRightScaleUp',
    'moveToTopScaleUp',
    'moveToBottomScaleUp',
    'scaleDownScaleUp',
    'glueLeftFromRight',
    'glueRightFromLeft',
    'glueBottomFromTop',
    'glueTopFromBottom',
    'flipRight',
    'flipLeft',
    'flipTop',
    'flipBottom',
    'fall',
    'newspaper',
    'pushLeftFromRight',
    'pushRightFromLeft',
    'pushTopFromBottom',
    'pushBottomFromTop',
    'pushLeftPullRight',
    'pushRightPullLeft',
    'pushTopPullBottom',
    'pushBottomPullTop',
    'foldLeftFromRight',
    'foldRightFromLeft',
    'foldTopFromBottom',
    'foldBottomFromTop',
    'moveToRightUnfoldLeft',
    'moveToLeftUnfoldRight',
    'moveToBottomUnfoldTop',
    'moveToTopUnfoldBottom',
    'roomToLeft',
    'roomToRight',
    'roomToTop',
    'roomToBottom',
    'cubeToLeft',
    'cubeToRight',
    'cubeToTop',
    'cubeToBottom',
    'carouselToLeft',
    'carouselToRight',
    'carouselToTop',
    'carouselToBottom',
    'slides',
    'slide',
  ];

  const size = animations.length;

  const [preset, setPreset] = useState(Math.floor(Math.random() * size));
  const [enterAnimation] = useState('');
  const [exitAnimation] = useState('');

  const [store, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const getRandomAnimation = () => {
      let newPreset = Math.floor(Math.random() * size);
      setPreset(newPreset);
    };
    getRandomAnimation();
  }, [location, size]);

  return (
    <div className="App">
      <Helmet>
        <title>Feel Good Gift</title>

        <meta name="title" content="Feel Good Gift" />
        <meta
          name="description"
          content="Here’s a personalized, hand-crafted thank-you gift to bring a smile to your heart and warm your soul."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://feelgood.sweetrush.com/" />
        <meta property="og:title" content="Feel Good Gift" />
        <meta
          property="og:description"
          content="Here’s a personalized, hand-crafted thank-you gift to bring a smile to your heart and warm your soul."
        />
        <meta property="og:image" content={shareImg} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://feelgood.sweetrush.com/" />
        <meta property="twitter:title" content="Feel Good Gift" />
        <meta
          property="twitter:description"
          content="Here’s a personalized, hand-crafted thank-you gift to bring a smile to your heart and warm your soul."
        />
        <meta property="twitter:image" content={shareImg} />
      </Helmet>

      <Media query="(max-width: 767px)">
        {(matches) =>
          matches ? (
            <Mobile />
          ) : (
            <Media query="(max-width: 1024px) and (orientation: portrait)">
              {(matches) =>
                matches ? (
                  <TabletPortrait />
                ) : (
                  <Context.Provider value={{ store, dispatch }}>
                    <Route
                      render={({ location }) => (
                        <PageTransition
                          preset={animations[preset]}
                          transitionKey={location.pathname}
                          enterAnimation={enterAnimation}
                          exitAnimation={exitAnimation}
                        >
                          <Switch location={location}>
                            <Route exact path="/" component={Intro} />
                            <Route exact path="/assessment" component={Quiz} />
                            <Route path="/results" component={ResultsSummary} />
                            <Route path="/typeSelector" component={TypeSelector} />
                            <Route path="/giftWrapping" component={GiftWrapping} />
                            <Route path="/adventurer" component={Adventurer} />
                            <Route path="/entertainer" component={Entertainer} />
                            <Route path="/chillaxer" component={Chillaxer} />
                            <Route path="/wanderer" component={Wanderer} />
                            <Route
                              path="/result-adventurer"
                              render={(props) => <ResultsSummary profile="adventurer" {...props} />}
                            />
                            <Route
                              path="/result-entertainer"
                              render={(props) => (
                                <ResultsSummary profile="entertainer" {...props} />
                              )}
                            />
                            <Route
                              path="/result-chillaxer"
                              render={(props) => <ResultsSummary profile="chillaxer" {...props} />}
                            />
                            <Route
                              path="/result-wanderer"
                              render={(props) => <ResultsSummary profile="wanderer" {...props} />}
                            />
                            <Redirect to="/" />
                          </Switch>
                        </PageTransition>
                      )}
                    />
                  </Context.Provider>
                )
              }
            </Media>
          )
        }
      </Media>
    </div>
  );
}

export default App;
