import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { Link } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';

import ClipLoader from 'react-spinners/ClipLoader';

import boxBg from '../../assets/images/boxes/adventurer/box-bg.jpg';
import boxBgOpen from '../../assets/images/boxes/adventurer/box-bg-open.jpg';
import introBtn from '../../assets/images/boxes/adventurer/intro-btn.svg';
import introBtnHover from '../../assets/images/boxes/adventurer/intro-btn-hover.svg';
import box from '../../assets/images/boxes/adventurer/box.png';

import popupBg from '../../assets/images/boxes/adventurer/popups/popup-bg.jpg';
import buttonX from '../../assets/images/boxes/adventurer/popups/x.svg';
import buttonXHover from '../../assets/images/boxes/adventurer/popups/x-hover.svg';
import bgAudio from '../../assets/images/boxes/adventurer/popups/bg-audio.png';
import popupBtn from '../../assets/images/boxes/adventurer/popups/btn.svg';
import popupBtnHover from '../../assets/images/boxes/adventurer/popups/btn-hover.svg';
import recipesPaper from '../../assets/images/boxes/adventurer/popups/recipes-paper.png';
import fortuneBg from '../../assets/images/boxes/adventurer/popups/fortune-cookie-bg.png';
import fortuneCookieInstant from '../../assets/images/boxes/adventurer/popups/fortune-cookie_instant.png';

import audio1 from '../../assets/audios/01_Deep_Noticing.mp3';
import audio2 from '../../assets/audios/02_Handling_Strong_Emotions.mp3';
import audio3 from '../../assets/audios/03_Gratitude.mp3';
import audio4 from '../../assets/audios/04_The_Adventurer_Mindful_Walk.mp3';

import leavesRight from '../../assets/images/boxes/adventurer/ilus-right.png';
import leavesLeft from '../../assets/images/boxes/adventurer/ilus-left.png';
import leavesTop from '../../assets/images/boxes/adventurer/ilus-top.png';
import leavesStatic from '../../assets/images/boxes/adventurer/ilus-static.png';

import introText from '../../assets/images/boxes/adventurer/intro-text.svg';

import recipesImg from '../../assets/images/boxes/adventurer/recipe.png';
import candyImg from '../../assets/images/boxes/adventurer/candy.png';
import cookieImg from '../../assets/images/boxes/adventurer/fortune-cookie.png';
import playlistImg from '../../assets/images/boxes/adventurer/playlist.png';
import interestImg from '../../assets/images/boxes/adventurer/interests.png';
import meditationImg from '../../assets/images/boxes/adventurer/meditation.png';

import recipesLabelImg from '../../assets/images/boxes/adventurer/hover-btns/recipes.svg';
import candyLabelImg from '../../assets/images/boxes/adventurer/hover-btns/candy.svg';
import cookieLabelImg from '../../assets/images/boxes/adventurer/hover-btns/cookie.svg';
import playlistLabelImg from '../../assets/images/boxes/adventurer/hover-btns/playlist.svg';
import interestLabelImg from '../../assets/images/boxes/adventurer/hover-btns/interest.svg';
import meditationLabelImg from '../../assets/images/boxes/adventurer/hover-btns/meditation.svg';

import interestPopupImg from '../../assets/images/boxes/adventurer/popups/interestsPopupImage.png';
import candyUpPopupImg from '../../assets/images/boxes/adventurer/popups/candyUp.png';
import candyDownPopupImg from '../../assets/images/boxes/adventurer/popups/candyDown.png';
import musicUpPopupImg from '../../assets/images/boxes/adventurer/popups/musicUp.png';
import musicDownPopupImg from '../../assets/images/boxes/adventurer/popups/musicDown.png';
import meditationUpPopupImg from '../../assets/images/boxes/adventurer/popups/meditationUp.png';
import meditationDownPopupImg from '../../assets/images/boxes/adventurer/popups/meditationDown.png';

import pinterest from '../../assets/images/boxes/adventurer/popups/pinterest.svg';

const Adventurer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [boxOpen, setBoxOpen] = useState(false);
  const [inHover, setHover] = useState(false);
  const [currentHover, setCurrentHover] = useState('');

  const showBox = () => {
    setBoxOpen(true);
  };

  useEffect(() => {
    let imageList = [
      boxBg,
      boxBgOpen,
      introBtn,
      introBtnHover,
      box,
      popupBg,
      buttonX,
      buttonXHover,
      bgAudio,
      popupBtn,
      popupBtnHover,
      recipesPaper,
      fortuneBg,
      fortuneCookieInstant,
      recipesImg,
      candyImg,
      cookieImg,
      playlistImg,
      interestImg,
      meditationImg,
      leavesRight,
      leavesLeft,
      leavesTop,
      leavesStatic,
      recipesLabelImg,
      candyLabelImg,
      cookieLabelImg,
      playlistLabelImg,
      interestLabelImg,
      meditationLabelImg,
      interestPopupImg,
      candyUpPopupImg,
      candyDownPopupImg,
      musicUpPopupImg,
      musicDownPopupImg,
      meditationUpPopupImg,
      meditationDownPopupImg,
    ];

    cacheImages(imageList);
  }, []);

  const cacheImages = async (srcArray) => {
    const preload = (src) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
      });

    const result = await Promise.all(srcArray.map(preload));
    if (result) setIsLoading(false);
  };

  const { quote, image } = useFetch();

  const pauseAllAudio = (activeAudio) => {
    var sounds = document.getElementsByTagName('audio');
    for (var i = 0; i < sounds.length; i++) {
      if (sounds[i].parentElement.className != activeAudio) {
        sounds[i].pause();
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="spinner-div">
          <ClipLoader className="spinner" />
        </div>
      ) : (
        <div
          className={boxOpen ? 'container-fluid adventurer open-box' : 'container-fluid adventurer'}
        >
          <div className="leaves">
            <div
              className={
                boxOpen ? 'animation-box leaves-right open-box' : 'animation-box leaves-right'
              }
            >
              <img src={leavesRight} alt="" />
            </div>
            <div
              className={boxOpen ? 'animation-box leaves-top open-box' : 'animation-box leaves-top'}
            >
              <img src={leavesTop} alt="" />
            </div>
            <div
              className={
                boxOpen ? 'animation-box leaves-left open-box' : 'animation-box leaves-left'
              }
            >
              <img src={leavesLeft} alt="" />
            </div>
            <div
              className={
                boxOpen ? 'animation-box leaves-static open-box' : 'animation-box leaves-static'
              }
            >
              <img src={leavesStatic} alt="" />
            </div>
          </div>
          <div className="content">
            {boxOpen ? (
              <div className="box">
                <div className="items">
                  <Popup
                    trigger={
                      <div
                        className={`item recipes ${
                          inHover ? (currentHover === 'recipes' ? 'main-hover' : 'hover') : ''
                        }`}
                        onMouseEnter={() => {
                          setHover(true);
                          setCurrentHover('recipes');
                        }}
                        onMouseLeave={() => setHover(false)}
                      >
                        <img src={recipesImg} alt="" />
                        <img src={recipesLabelImg} alt="" className="label" />
                      </div>
                    }
                    modal
                    className="adventurer-popup"
                  >
                    {(close) => (
                      <div className="adventurer-popup recipes">
                        <button className="close" onClick={close}></button>
                        <div className="popup-content">
                          <div className="popup-text">
                            <div className="title">Recipes</div>
                            <div className="body">
                              Your hunger for experience extends to your palate, Adventurer: you’re
                              always up for trying something new. Wherever you find yourself this
                              season, these sweet and savory specialties will give you—and your best
                              travel buddies—a reason to gather.
                            </div>
                            <a
                              href="https://rise.articulate.com/share/3EIENGgzPul1rJuPjS7ODCu_Y3lC2A5L"
                              className="link"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Go to recipes
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>

                  <Popup
                    trigger={
                      <div
                        className={`item playlist ${
                          inHover ? (currentHover === 'playlist' ? 'main-hover' : 'hover') : ''
                        }`}
                        onMouseEnter={() => {
                          setHover(true);
                          setCurrentHover('playlist');
                        }}
                        onMouseLeave={() => setHover(false)}
                      >
                        <img src={playlistImg} alt="" />
                        <img src={playlistLabelImg} alt="" className="label" />
                      </div>
                    }
                    modal
                    className="adventurer-popup"
                  >
                    {(close) => (
                      <div className="adventurer-popup playlist">
                        <button className="close" onClick={close}></button>
                        <div className="popup-content">
                          <img src={musicUpPopupImg} alt="" className="music-up" />
                          <img src={musicDownPopupImg} alt="" className="music-down" />
                          <div className="popup-text">
                            <div className="title">Music</div>
                            <div className="body">
                              No expedition is complete without a great soundtrack to hold all the
                              right notes. Cue up this curated playlist to energize you on your next
                              adventure, whether you’re white-water rafting or whetting your
                              appetite with a local food tour.
                            </div>
                            <a
                              href="https://open.spotify.com/playlist/7jYRs7Du2v1Xs5Q4wQiW9S?si=a-lCJKMuRaqnRyMclBGORA"
                              className="link"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Listen
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>

                  <Popup
                    trigger={
                      <div
                        className={`item interest ${
                          inHover ? (currentHover === 'interest' ? 'main-hover' : 'hover') : ''
                        }`}
                        onMouseEnter={() => {
                          setHover(true);
                          setCurrentHover('interest');
                        }}
                        onMouseLeave={() => setHover(false)}
                      >
                        <img src={interestImg} alt="" />
                        <img src={interestLabelImg} alt="" className="label" />
                      </div>
                    }
                    modal
                    className="adventurer-popup"
                  >
                    {(close) => (
                      <div className="adventurer-popup interest">
                        <button className="close" onClick={close}></button>
                        <div className="popup-content">
                          <div className="popup-text">
                            <div className="title">Hobbies and Interests</div>
                            <div className="body">
                              You make friends wherever you go, Adventurer—and you go everywhere.
                              You feel most at home when you’re meeting new people and setting off
                              on new journeys. These activities will help you connect with your
                              fellow travelers (safely) and check off items from your bucket list.
                            </div>
                            <a
                              href="https://pin.it/yoDjI9e"
                              className="link"
                              target="_blank"
                              rel="noreferrer"
                            >
                              My board <img className="pinterest" src={pinterest} alt="" />
                            </a>
                          </div>
                          <img className="popup-image" src={interestPopupImg} alt="" />
                        </div>
                      </div>
                    )}
                  </Popup>

                  <Popup
                    trigger={
                      <div
                        className={`item candy ${
                          inHover ? (currentHover === 'candy' ? 'main-hover' : 'hover') : ''
                        }`}
                        onMouseEnter={() => {
                          setHover(true);
                          setCurrentHover('candy');
                        }}
                        onMouseLeave={() => setHover(false)}
                      >
                        <img src={candyImg} alt="" />
                        <img src={candyLabelImg} alt="" className="label" />
                      </div>
                    }
                    modal
                    className="adventurer-popup"
                  >
                    {(close) => (
                      <div className="adventurer-popup candy">
                        <button className="close" onClick={close}></button>
                        <div className="popup-content">
                          <img src={candyUpPopupImg} alt="" className="candy-up" />
                          <img src={candyDownPopupImg} alt="" className="candy-down" />
                          <div className="popup-text">
                            <div className="title">sharing and caring</div>
                            <div className="body">
                              The time is always right to do good things for family, friends, and
                              friends we haven’t met. Your magnetic personality, Adventurer, makes
                              everyone feel at home—and we want to share in the joy! Whether you’re
                              volunteering or lending a hand to a fellow backpacker, light up our
                              Instagram feed by sharing a picture or a video and use #goodthings.
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>

                  <Popup
                    trigger={
                      <div
                        className={`item meditation ${
                          inHover ? (currentHover === 'meditation' ? 'main-hover' : 'hover') : ''
                        }`}
                        onMouseEnter={() => {
                          setHover(true);
                          setCurrentHover('meditation');
                        }}
                        onMouseLeave={() => setHover(false)}
                      >
                        <img src={meditationImg} alt="" />
                        <img src={meditationLabelImg} alt="" className="label" />
                      </div>
                    }
                    modal
                    className="adventurer-popup"
                  >
                    {(close) => (
                      <div className="adventurer-popup meditation">
                        <button
                          className="close"
                          onClick={() => {
                            close();
                            pauseAllAudio();
                          }}
                        ></button>
                        <div className="popup-content">
                          <img src={meditationUpPopupImg} alt="" className="meditation-up" />
                          <img src={meditationDownPopupImg} alt="" className="meditation-down" />
                          <div className="popup-text">
                            <div className="title">Meditation</div>
                            <div className="body">
                              <p>
                                Your energy and charisma are the stuff of legend, Adventurer—but
                                even legends need to rest. A mindfulness practice will energize you
                                and help direct your resilience and empathy inward. We created this
                                collection of guided experiences to help you tune into your practice
                                on the road and in the air.<br></br>Join us for these four
                                revitalizing sessions:
                              </p>
                              <div className="audio-container container-fluid">
                                <div className="row">
                                  <div className="col">
                                    <span>Deep Noticing</span>
                                    <AudioPlayer
                                      src={audio1}
                                      className="audio-1"
                                      layout="horizontal-reverse"
                                      customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                      customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                      onPlay={(e) => {
                                        pauseAllAudio(e.currentTarget.parentElement.className);
                                      }}
                                    />
                                  </div>
                                  <div className="col">
                                    <span>Handling Strong Emotions</span>
                                    <AudioPlayer
                                      src={audio2}
                                      className="audio-2"
                                      layout="horizontal-reverse"
                                      customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                      customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                      onPlay={(e) => {
                                        pauseAllAudio(e.currentTarget.parentElement.className);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <span>Gratitude</span>
                                    <AudioPlayer
                                      src={audio3}
                                      className="audio-3"
                                      layout="horizontal-reverse"
                                      customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                      customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                      onPlay={(e) => {
                                        pauseAllAudio(e.currentTarget.parentElement.className);
                                      }}
                                    />
                                  </div>
                                  <div className="col">
                                    <span>Mindful Walk</span>
                                    <AudioPlayer
                                      src={audio4}
                                      className="audio-4"
                                      layout="horizontal-reverse"
                                      customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                                      customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                                      onPlay={(e) => {
                                        pauseAllAudio(e.currentTarget.parentElement.className);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>

                  <Popup
                    trigger={
                      <div
                        className={`item cookie ${
                          inHover ? (currentHover === 'cookie' ? 'main-hover' : 'hover') : ''
                        }`}
                        onMouseEnter={() => {
                          setHover(true);
                          setCurrentHover('cookie');
                        }}
                        onMouseLeave={() => setHover(false)}
                      >
                        <img src={cookieImg} alt="" />
                        <img src={cookieLabelImg} alt="" className="label" />
                      </div>
                    }
                    modal
                    className="adventurer-popup"
                  >
                    {(close) => (
                      <div className="adventurer-popup cookie">
                        <button className="close" onClick={close}></button>
                        <div className="popup-content">
                          <div className="popup-text">
                            <div className="text">
                              <div className="title">Fortune Cookie</div>
                              <div className="body">{quote.text}</div>
                            </div>
                            <div className="image">
                              <div className="thumbnail">
                                <img className="thumbnail-img" src={image.thumbnail} alt="" />
                              </div>
                              <a href={image.full} className="download-btn btn" download>
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
            ) : (
              <div className="intro">
                <img src={introText} alt="" className="intro-text" />
                <button onClick={showBox} className="intro-btn"></button>
              </div>
            )}
          </div>
          {boxOpen ? (
            <Link className="link-container" to={{ pathname: 'typeSelector' }}>
              <button className="other-types-button">check other types</button>
            </Link>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
};

export default Adventurer;
